import { useState } from "react"


export function NewItemForm(props) {
  
  const [newItem, setNewItem] = useState("")
  

  function handleSubmit(e) {
    e.preventDefault()

    props.onSubmit(newItem)

    setNewItem("")
  }
return (
<form onSubmit={handleSubmit} className="new-item-input">
    <div className="form-row">
      <label htmlFor="item" id="new-item-label"> New Item </label>
      <input value={newItem}
      onChange={e => setNewItem(e.target.value)} 
      type="text" id="item"></input>
    </div><br></br>
    <button className="button">Add</button>
    </form>
)
}