export function NewItem({ completed, id, title, toggleToDo, deleteToDo }) {
  return (
    <li>
      <label id="label-output">
        <input 
        type="checkbox" 
        checked={completed}
        onChange={e => toggleToDo(id, e.target.checked)}/>
        {title}
      </label>
      <button 
        onClick={() => deleteToDo(id)} 
        className="button-delete">
        Delete
      </button>
    </li>
  )
  }