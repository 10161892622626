import { useEffect, useState } from "react"
import { NewItemForm } from "./NewItemForm"
import "./App.css"
import { NewItemList } from "./NewItemList"


export default function App() {
  const [todos, setToDos] = useState(() => {
    const localValue = localStorage.getItem("ITEMS")
    if (localValue === null) return []
    
    return JSON.parse(localValue)
  })
 
  useEffect(() => {
    localStorage.setItem("ITEMS", JSON.stringify(todos))
  }, [todos])
 
function addToDo(title) {
  setToDos(currentToDos => {
    return [
      ...currentToDos,
      { id: crypto.randomUUID(), title: title, completed: false},
    ]
  })
}

  function toggleToDo(id, completed) {
    setToDos(currentToDos => {
      return currentToDos.map(todo => {
        if (todo.id === id) {
          return { ...todo, completed }
        }
        return todo
      })
    })
  }
  function deleteToDo(id) {
    setToDos(currentToDos => {
      return currentToDos.filter(todo => todo.id !== id)
    })
  }
  
  return ( 
  <>
  <h1 className="header">Shopping List</h1>
  <NewItemForm onSubmit={addToDo}/>
  
  <NewItemList todos={todos} toggleToDo={toggleToDo} deleteToDo={deleteToDo} />
  </>  
  )
}

