import { NewItem } from "./NewItem"
export function NewItemList({ todos, toggleToDo, deleteToDo }) {
  return (
  <ul className="list">
  {todos.length === 0 && "List Empty, Add Items" }
  {todos.map(todo => {
    return ( 
      <NewItem {...todo} 
      key={todo.id} toggleToDo={toggleToDo} deleteToDo={deleteToDo}/>
    )
    })}
    </ul>
  )
}
